<template>
  <fragment>
    <!--<v-col
      cols="12"
      sm="3"
      md="3"
    >
      <v-text-field
        v-model="contact.name"
        :label="$t('lbl.name')"
        outlined
        dense
        hide-details
        :disabled="$store.state.app.onlyShow"
      ></v-text-field>
    </v-col>-->
    <v-col
      cols="12"
      sm="3"
      md="3"
    >
      <v-text-field
        v-model="contact.rol"
        :label="$t('lbl.rol')"
        outlined
        dense
        hide-details
        :disabled="$store.state.app.onlyShow"
      ></v-text-field>
    </v-col>
    <v-col
      v-if="!$store.state.app.onlyShow"
      cols="12"
      sm="1"
      md="1"
    >
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mt-1 mx-2"
            fab
            dark
            x-small
            color="error"
            @click="deleteContactHotel(pos)"
          >
            <v-icon
              v-bind="attrs"
              small
              v-on="on"
            >
              {{ icons.mdiTrashCan }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('btn.delete') }}</span>
      </v-tooltip>
    </v-col>
    <v-col
      cols="12"
      sm="3"
      md="3"
    >
      <v-row no-gutters>
        <!--<v-col
          cols="12"
          md="12"
        >
          <strong>{{ $t('lbl.email') }}</strong>
          <v-tooltip
            v-if="!$store.state.app.onlyShow"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2"
                fab
                dark
                x-small
                color="primary"
                @click="adicionarEmailContactHotel(pos)"
              >
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiPlus }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('btn.insert') }}</span>
          </v-tooltip>
        </v-col>-->
        <v-col
          cols="12"
          md="12"
        >
          <ContactEmail
            v-for="(email, i) in contact.emails"
            :key="i"
            :pos="pos"
            :index="i"
            :email="email"
          />
        </v-col>
      </v-row>
    </v-col>

    <v-col
      cols="12"
      sm="5"
      md="5"
    >
      <v-row>
        <!--<v-col
          cols="12"
          md="12"
        >
          <strong>{{ $t('lbl.telefonos') }}</strong>
          <v-tooltip
            v-if="!$store.state.app.onlyShow"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2"
                fab
                dark
                x-small
                color="primary"
                @click="adicionarTelefonoContactHotel(pos)"
              >
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ icons.mdiPlus }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('btn.insert') }}</span>
          </v-tooltip>
        </v-col>-->
        <v-col
          cols="12"
          md="12"
        >
          <ContactTelefono
            v-for="(telefono, i) in contact.telefonos"
            :key="i"
            :pos="pos"
            :index="i"
            :telefono="telefono"
          />
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      md="12"
    >
      <v-divider class="mt-2 mb-2"></v-divider>
    </v-col>
  </fragment>
</template>

<script>
import { mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
} from '@mdi/js'
import ContactEmail from './ContactEmail.vue'
import ContactTelefono from './ContactTelefono.vue'

export default {
  components: {
    ContactEmail,
    ContactTelefono,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    contact: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
  },
  data() {
    return {
      isLoading: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
      },
      loading: false,
    }
  },

  created() {
    this.isLoading = true
  },
  methods: {
    ...mapMutations(['deleteContactHotel', 'adicionarEmailContactHotel', 'adicionarTelefonoContactHotel']),
  },
}
</script>
